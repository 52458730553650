import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import Layout from '../components/layout';
import Artists from '../components/artists';
import SEO from '../components/seo';
import DaysLeft from '../components/daysLeft';
import '../assets/styles/components/todays-entry.scss';
import '../assets/styles/pages/artist.scss';

const Artist = ({ pageContext: { artist } }) => {
  const [isActive, setIsActive] = useState(true);
  const data = useStaticQuery(graphql`
    query {
      comingSoonImage: file(relativePath: { eq: "comming-soon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2580) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bannerImage: file(relativePath: { eq: "banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bannerTabletImage: file(relativePath: { eq: "banner-ipad.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1536) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bannerMobileImage: file(relativePath: { eq: "banner-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const onError = () => {
    setIsActive(false);
  };

  const showVideo = (date) => {
    const days = DaysLeft(date);

    if (days <= 0 && isActive !== false) {
      return <YouTube containerClassName="inner-video-wrapper" videoId={artist.video} opts={opts} onError={(e) => onError(e)} />;
    }

    return <Img fluid={data.comingSoonImage.childImageSharp.fluid} />;
  };

  return (
    <Layout>
      <SEO title={artist.title} description={artist.description} />
      <div className="banner-image-wrapper">
        <Link to="/">
          <Img fluid={data.bannerImage.childImageSharp.fluid} className="show-desktop" />
          <Img fluid={data.bannerTabletImage.childImageSharp.fluid} className="show-tablet" />
          <Img fluid={data.bannerMobileImage.childImageSharp.fluid} className="show-mobile" />
        </Link>
      </div>
      <div className="template-artist">
        <div className="featured-artist">
          <div className="featured-artist-copy-wrapper">
            <h1 className="h2 artist-name">{artist.name}</h1>
            <div
              className="description"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: artist.body,
              }}
            />
          </div>
          <div className="video-wrapper">
            {showVideo(artist.releaseDate)}
          </div>
        </div>
      </div>
      <Artists />
    </Layout>
  );
};

Artist.propTypes = {
  pageContext: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default Artist;
